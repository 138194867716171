import React from 'react';
import "./Button.css";

function Button(props) {
  return (
    <button onClick={props.onClick}>
      {props.iconLeft ? <img className="icon icon-left" src={props.iconLeft} alt="" /> : "" }
      {props.icon ? <img className="icon" src={props.icon} alt="" /> : "" }
      {props.text ? <label>{props.text}</label> : ""}
      {props.iconRight ? <img className="icon icon-right" src={props.iconRight} alt="" /> : "" }
    </button>
  );
}

export default Button;
