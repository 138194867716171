export async function fetchBackstage(path) {
    try {
      const response = await fetch(`https://backstage.unixy.org/${path}`);
      const resp = await response.json();


      return resp;
    } catch (e) {
      console.error("Caught error while fetching scene");
      throw e;
    }
}