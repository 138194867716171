import React, { useState, useCallback } from 'react';
import hashtag from '../../assets/hashtag.svg';
import dropdown from '../../assets/dropdown.svg';
import "./ShowPicker.css";

function ShowPicker(props) {
  const [showFilter, setShowFilter] = useState(false);
  const [show, setShow] = useState();
  const handleFilterClick = useCallback(() => {
    setShowFilter(!showFilter);
  });

  const handleShowClick = useCallback((e) => {
    setShowFilter(false);
    props.setShowFn(e.target.getAttribute('codename'));
  });

  // TODO use real api
  const sampleShowListing = [{"Name":"Frasier","Codename":"frasier"},{"Name":"Friends","Codename":"friends"},{"Name":"Seinfeld","Codename":"seinfeld"},{"Name":"Curb Your Enthusiasm","Codename":"curb"},{"Name":"Rick and Morty","Codename":"rickandmorty"},{"Name":"Family Guy","Codename":"familyguy"},{"Name":"Pinky and the Brain","Codename":"pinkybrain"},{"Name":"Southpark","Codename":"southpark"},{"Name":"Futurama","Codename":"futurama"},{"Name":"The Simpsons","Codename":"simpsons"},{"Name":"King of the Hill","Codename":"koth"},{"Name":"House of Cards","Codename":"houseofcards"},{"Name":"Game of Thrones","Codename":"got"},{"Name":"Conan O'Brien","Codename":"conan"},{"Name":"House, M.D.","Codename":"house"},{"Name":"The Wire","Codename":"wire"},{"Name":"Archer","Codename":"archer"},{"Name":"Arrested Development","Codename":"ad"},{"Name":"I Love Lucy","Codename":"lucy"},{"Name":"The Animaniacs","Codename":"animaniacs"},{"Name":"Looney Tunes","Codename":"looneytunes"},{"Name":"The Office","Codename":"office"},{"Name":"The West Wing","Codename":"westwing"},{"Name":"The Newsroom","Codename":"newsroom"},{"Name":"Bob's Burgers","Codename":"burgers"},{"Name":"Silicon Valley","Codename":"sv"},{"Name":"Spongebob Squarepants","Codename":"spongebob"},{"Name":"Star Trek","Codename":"startrek"},{"Name":"It's Always Sunny in Philadelphia","Codename":"sunny"},{"Name":"Letterkenny","Codename":"letterkenny"},{"Name":"Grey's Anatomy","Codename":"greys"}];
  const shows = sampleShowListing.map((show) => <li onClick={handleShowClick} className="dropdown__item" codename={show.Codename} key={show.Codename}><p codename={show.Codename}>{show.Name}</p></li>);

  return (
    <div className="stage__header">
      <div className="dropdown__header" onClick={handleFilterClick}>
        <img className="icon icon--hashtag" src={hashtag} alt="" />
        <p className="stage__show noselect">{props.show}</p>
        <img className="icon icon--dropdown" src={dropdown} alt="" />
      </div>
      { showFilter ? <ul className="dropdown__results noselect">{shows}</ul> : null }
    </div>
    );
  }
  export default ShowPicker;