import { fetchBackstage } from '../../utils';
import {BrowserRouter as Router, Link, useNavigate, Navigate} from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Scene from '../Scene/Scene';
import ShowPicker from '../ShowPicker/ShowPicker';
import NavBottom from '../NavBottom/NavBottom';
import "./Stage.css";

function Stage(props) {
  let fetching = false;
  const navigate = useNavigate();
  const [show, setShow] = useState();
  const [actions, setActions] = useState([]);

  const setShowFn = async (show) => {
    setShow(show);
    navigateToRandomSceneFn(show);
  };

  const navigateToRandomSceneFn = async (_show) => {
    const randScene = await fetchBackstage(`random/${_show}`);
    navigate(`/id/${randScene[0].id}`);
  };

  // fetch new scene when props.id changes
  useEffect(async () => {
    if (!fetching && props.id) {
      fetching = true;
      const scene = await fetchBackstage(`id/${props.id}`);

      if (scene) {
        console.log(scene)
        setShow(scene.show);
        setActions(scene.actions);
      }
      fetching = false;
    }
  }, [props.id]);

  return (
    <div className="center">
      <section className="stage__wrapper">
        <section className="stage">
          <ShowPicker show={show} setShowFn={setShowFn} />
          <Scene actions={actions} />
        </section>
        <NavBottom show={show} navigateToRandomSceneFn={navigateToRandomSceneFn}/>
      </section>
    </div>
  );
}


export default Stage;