import React from 'react';
import ReactDOM from 'react-dom';
import "./Header.css"
import logo from "../../assets/_logo.svg"

function Header() {
  return (
    <a href="/">
      <header>
        <img className="logo" src={logo} alt="Text Theater" />
      </header>
    </a>
  );
}
export default Header;