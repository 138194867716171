import React, { useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Link, useNavigate, Navigate} from 'react-router-dom';
import { fetchBackstage } from '../../utils';

import "./NavBottom.css"
import Button from "../Button/Button"
import next from "../../assets/next.svg"
import prev from "../../assets/prev.svg"
import up from "../../assets/updoot.svg"
import down from "../../assets/downdoot.svg"

function NavBottom(props) {
  let navigate = useNavigate();
  const hist = JSON.parse(localStorage.getItem("hist")) || [];

  const handlePrev = useCallback(async () => {
    if (hist) {
      localStorage.setItem('hist', JSON.stringify(hist.slice(0, -1)));
      if (hist.length > 2) {
        let prev = hist[hist.length - 2]
        navigate(`/id/${prev}`);
      }
    }
  });

  const handleNext = useCallback(() => props.navigateToRandomSceneFn(props.show));

  return (
    <section className="navBottom__wrapper">
      <Button iconLeft={prev} text="Prev Scene" onClick={handlePrev} />
      {/* <div className="votes">
        <Button icon={down} />
        <label>vote</label>
        <Button icon={up} />
      </div> */}
      <Button iconRight={next} text="New Scene" onClick={handleNext} />
    </section>
  );
}
export default NavBottom;