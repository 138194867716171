import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Navigate } from "react-router-dom";

import { fetchBackstage } from './utils';
import Stage from './components/Stage/Stage';
import Header from './components/Header/Header';

function Main() {
  const params = useParams();
  const [sceneId, setSceneId] = useState();
  const hist = JSON.parse(localStorage.getItem("hist")) || [];

  const navigate = useNavigate();
  const [show, setShow] = useState();

  if (params.sceneId) {
    // update state if url changed
    if (params.sceneId !== sceneId) {
      setSceneId(params.sceneId);
    }

    // save to state if not last
    if (hist[hist.length - 1] !== params.sceneId) {
      localStorage.setItem('hist', JSON.stringify(hist.concat([params.sceneId])));
    }

    return <React.StrictMode>
      <Header />
      <Stage id={sceneId} />
    </React.StrictMode>
  } else {
    // don't have id - pick one randomly
    const newSceneId = Math.floor(Math.random() * 100) + 1;

    return <React.StrictMode>
      <Header />
      <Navigate to={`/id/${newSceneId}`} />
    </React.StrictMode>
  }

}

export default Main;