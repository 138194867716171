import React, { useState, useCallback } from 'react';
import Line from './Line'
import "./Line.css"

function Scene(props) {
  let lines = [];
  if (props.actions) {
    lines = props.actions.map((text, idx) => <Line key={idx} content={text} />)
  }

  return (
      <ul>
        {lines}
      </ul>
  );
}

export default Scene;
