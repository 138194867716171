import React from 'react';
import ReactDOM from 'react-dom';
import './pages/reset.css';
import './pages/index.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from './main';

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={ <Main /> } />
      <Route path="/id" element={ <Main /> }>
        <Route path=":sceneId" element={ <Main /> } />
      </Route>
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);