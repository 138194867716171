import React from 'react';
import "./Line.css"

function Line(props) {
  if (props.content.setting) {
    // handle as a scene setting
    return (
      <li className="setting">
        <p>
          {props.content.setting}
        </p>
      </li>
    );
  } else {
    // handle as a character dialogue
    return (
      <li className="dialogue">
        <p>
          <span className="time">8:55 PM</span>
          {/* fixme: <div> cannot appear as a descendant of <p> */}
          <span className="dialogue__wrapper">
            <span className="character">{props.content.character}</span>
            <span className="dialogue">{props.content.line}</span>
          </span>
        </p>
      </li>
    );
  }

}

export default Line;
